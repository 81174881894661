import React from "react";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import HomePage from "./pages/Home"
import RequestDeleteUserPage from "./pages/RequestDeleteUserPage"
import NoMatchPage from "./pages/NoMatch";
import './App.css';
import DeleteUserAccountStep1Page from "./pages/DeleteUserAccountStep1Page";
import DeleteUserAccountStep2Page from "./pages/DeleteUserAccountStep2Page";

const router = createBrowserRouter([
    { path: "/", Component: HomePage },
    { path: "/request", Component: RequestDeleteUserPage },
    { path: "/delete-account", Component: DeleteUserAccountStep1Page },
    { path: "/delete-account-confirm", Component: DeleteUserAccountStep2Page },
    { path: "*", Component: NoMatchPage },
]);

export default function App() {
    return <RouterProvider router={router} />;
}