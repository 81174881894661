import AimcastLogo from "../assets/AimcastLogo.svg";
import React from "react";

function NoMatchPage() {
    return (
        <div>
            <div className="container mx-auto ">
                <div className="flex h-screen">
                    <div className="m-auto">
                        <a href="https://aimcast.com" target="_blank">
                            <img className="h-32" src={AimcastLogo} alt="Aimcast Website"/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NoMatchPage;
