import React, { useEffect, useState, useRef } from 'react';
import Datepicker from "react-tailwindcss-datepicker";
import { useForm } from "react-hook-form"
import { FaCopy } from 'react-icons/fa';
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import AimcastIcon from '../assets/AimcastIcon.png';
import AimcastLogo from '../assets/AimcastLogo.svg';

const API_URL = process.env.REACT_APP_API_URL;


const formSchema = yup
    .object({
        email: yup.string().email().required(),
        reason: yup.string().optional(),
    })
    .required()

const DeleteUserAccountStep1Page = () => {
    const currentHost = window.location.origin;
    const [submitted, setSubmitted] = useState(false);

    const {
        register,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(formSchema),
    })

    const buttonStyle = {
        backgroundColor: '#20B2AA',
    };


    const onSubmit = async (data) => {

        try {
            console.log(data);

            // const response = await fetch( API_URL + '/request-delete-account', {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json'
            //     },
            //     body: JSON.stringify(data)
            // });

            // if (response.ok){
            //     const data = await response.json();
            //     setSubmitted(true)
            // }

        } catch (error) {
            console.error('Error submitting form data:', error);
        }

    }

    return (
        <div>
            <section className="text-gray-700 body-font relative">
                <div className="container px-5 py-5 mx-auto">

                    <div className="flex flex-col items-center justify-center max-h-full">
                        <a href="https://aimcast.com" target="_blank"  rel="noreferrer">
                            <img className="h-32" src={AimcastLogo} alt="Aimcast Website"/>
                        </a>
                    </div>


                    <div className="flex flex-col text-center w-full mb-12">
                        <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
                            Delete your Aimcast Account
                        </h1>
                    </div>

                    <div className="flex flex-col w-full mb-12 space-y-3">
                        <p className="lg:w-2/3 mx-auto leading-relaxed text-base mb-6">
                            You’re trying to delete your Aimcast account. This action is irreversible and will
                            permanently remove all of your account information, including any saved preferences or data.

                        </p>
                        <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
                            Please enter the email address associated with your account to proceed with the deletion
                            process:
                        </p>

                    </div>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="lg:w-1/2 md:w-2/3 mx-auto">
                            <div className="flex flex-wrap -m-2">

                                {submitted
                                    ?
                                    <div
                                        className="p-4 w-full mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400"
                                        role="alert">
                                    <span className="font-medium">Thank you!</span> Your request has been submitted and will be reviewed shortly.
                                    </div>
                                    : ""
                                }


                                <div className="p-2 w-full">
                                    <div className="relative">
                                        <label htmlFor="email" className="leading-7 text-sm text-gray-600">Email</label>
                                        <input
                                            type="email"
                                            className="w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                            placeholder="example@aimcast.com"
                                            required
                                            {...register("email", {
                                                required: true
                                            })}
                                        />
                                        <p>{errors.email?.message}</p>
                                    </div>
                                </div>
                                <div className="p-2 w-full">
                                    <div className="relative">
                                        <label htmlFor="reason"
                                               className="leading-7 text-sm text-gray-600">Reason</label>
                                        <textarea
                                            className="w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out min-h-full"
                                            placeholder="Write your thoughts here..."
                                            maxLength={255}
                                            {...register("reason")}
                                        />
                                        <p>{errors.reason?.message}</p>
                                    </div>
                                </div>


                                <div className="p-2 w-full">
                                    <button style={buttonStyle}
                                            className="flex mx-auto text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">
                                        Send Confirmation Email
                                    </button>
                                </div>


                                <div className="pt-16 w-full flex items-center justify-center text-xs">
                                    &copy; {new Date().getFullYear()} AIMCAST, LLC. All Rights Reserved.
                                </div>

                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    );
};

export default DeleteUserAccountStep1Page;