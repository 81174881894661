// components/Home.js
import React from 'react';
import AimcastLogo from '../assets/AimcastLogo.svg';

const HomePage = () => {

	return (
		<div>
			<div className="container mx-auto ">
				<div className="flex h-screen">
					<div className="m-auto">
						<a href="https://aimcast.com" target="_blank" rel="noreferrer">
							<img className="h-32" src={AimcastLogo} alt="Aimcast Website"/>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HomePage;