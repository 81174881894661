import React, { useEffect, useState, useRef } from 'react';
import Datepicker from "react-tailwindcss-datepicker";
import { useForm } from "react-hook-form"
import { FaCopy } from 'react-icons/fa';
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import AimcastIcon from '../assets/AimcastIcon.png';
import AimcastLogo from '../assets/AimcastLogo.svg';
import {nanoid} from "nanoid";
import {useNavigate, useLocation} from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL;

const formSchema = yup
    .object({
        email: yup.string().email().required(),
        reason: yup.string().optional(),
    })
    .required()

const parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split('')
            .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join('')
    );



    return JSON.parse(jsonPayload);
}

const DeleteUserAccountStep2Page = () => {
    //const currentHost = window.location.origin;
    const [restoreCode, setRestoreCode] = useState(nanoid());
    const [autToken, setAuthToken] = useState('');
    const [decodedToken, setDecodedToken] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();


    useEffect(() => {
        // Extract token from query string when component mounts
        const searchParams = new URLSearchParams(location.search);
        const tokenFromQuery = searchParams.get('token');
        if (tokenFromQuery) {
            setAuthToken(tokenFromQuery);
        } else {
            navigate('/delete-account')
        }

    }, [location.search]);

    useEffect(() => {
        if (autToken) {
            // Decode the JWT token
            try{
                const decoded = parseJwt(autToken);
                setDecodedToken(decoded);
            } catch (e){
                navigate('/delete-account')
            }

        }
    }, [autToken]);

    const onSubmit = async (data) => {

        try {
            console.log(data);

            // const response = await fetch( API_URL + '/request-delete-account', {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json'
            //     },
            //     body: JSON.stringify(data)
            // });
            //
            // if (response.ok){
            //     const data = await response.json();
            //     setSubmitted(true)
            // }

        } catch (error) {
            console.error('Error submitting form data:', error);
        }

    }

    return (
        <div>
            <section className="text-gray-700 body-font relative">
                <div className="container px-5 py-5 mx-auto">

                    <div className="flex flex-col items-center justify-center max-h-full">
                        <a href="https://aimcast.com" target="_blank"  rel="noreferrer">
                            <img className="h-32" src={AimcastLogo} alt="Aimcast Website"/>
                        </a>
                    </div>


                    <div className="flex flex-col text-center w-full mb-12">
                        <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-red-600">
                            Confirm Your Account Deletion
                        </h1>
                    </div>

                    <div className="flex flex-col w-full mb-12 space-y-3">
                        <p className="lg:w-2/3 mx-auto leading-relaxed text-base"> {decodedToken?.name}, </p>

                        <p className="lg:w-2/3 mx-auto leading-relaxed text-base">This action cannot be undone. All your
                            data, including profile information, preferences, and any associated content, will be
                            permanently erased from our system.</p>

                        <p className="lg:w-2/3 mx-auto leading-relaxed text-base">If you proceed, you will lose
                            access to your account and all its features.</p>

                        <p className="lg:w-2/3 mx-auto leading-relaxed text-base">To help you restore your account
                            in the future, we've generated a unique restore code for you. Please make sure to keep this
                            code safe if you ever decide to return.</p>

                        <p className="lg:w-2/3 mx-auto leading-relaxed text-base m-7">Restore
                            Code: <strong>{restoreCode}</strong></p>


                        <p className="lg:w-2/3 mx-auto leading-relaxed text-base">If you have any concerns or
                            questions, feel free to contact our support team at <a
                                href="mailto:support@aimcast.com">support@aimcast.com</a>.
                        </p>


                    </div>


                    <div className="lg:w-1/2 md:w-2/3 mx-auto">
                        <div className="flex flex-wrap -m-2">

                            <div className="p-2 w-full">
                                <button
                                    className="flex mx-auto text-white bg-red-500 border-0 py-2 px-8 focus:outline-none hover:bg-red-500 rounded text-lg">
                                    Confirm Delete Account
                                </button>
                            </div>


                            <div className="pt-16 w-full flex items-center justify-center text-xs">
                                &copy; {new Date().getFullYear()} AIMCAST, LLC. All Rights Reserved.
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default DeleteUserAccountStep2Page;